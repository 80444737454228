import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        {/* <path
          d="M 6.425 17.775 L 0 0.9 L 2.325 0 L 7.825 15.175 L 13.35 0.075 L 15.5 0.9 L 9.1 17.775 L 6.425 17.775 Z M 18 17.725 L 18 0.325 A 27.735 27.735 0 0 1 18.714 0.254 Q 19.358 0.197 20.163 0.15 A 41.092 41.092 0 0 1 21.368 0.099 Q 22.194 0.075 23.15 0.075 A 13.913 13.913 0 0 1 24.996 0.189 Q 26.903 0.445 28.088 1.275 A 4.065 4.065 0 0 1 29.258 2.497 Q 29.749 3.31 29.795 4.356 A 4.982 4.982 0 0 1 29.8 4.575 A 4.47 4.47 0 0 1 29.666 5.695 A 3.408 3.408 0 0 1 29.075 6.925 Q 28.35 7.875 26.925 8.5 A 7.668 7.668 0 0 1 28.084 9.013 Q 28.709 9.359 29.167 9.795 A 4.222 4.222 0 0 1 29.5 10.15 A 3.733 3.733 0 0 1 30.32 12.02 A 5.035 5.035 0 0 1 30.375 12.775 A 4.962 4.962 0 0 1 30.044 14.622 Q 29.596 15.748 28.546 16.55 A 5.63 5.63 0 0 1 28.512 16.575 A 6.09 6.09 0 0 1 26.712 17.481 Q 25.188 17.975 23.05 17.975 Q 21.525 17.975 20.163 17.913 Q 18.862 17.853 18.074 17.736 A 9.235 9.235 0 0 1 18 17.725 Z M 23.225 9.675 L 20.375 9.675 L 20.375 15.85 A 22.334 22.334 0 0 0 21.917 15.971 A 28.776 28.776 0 0 0 23.225 16 Q 24.702 16 25.742 15.655 A 4.225 4.225 0 0 0 26.712 15.2 Q 27.844 14.468 27.941 13.13 A 3.542 3.542 0 0 0 27.95 12.875 A 2.932 2.932 0 0 0 27.732 11.725 Q 27.433 11.016 26.725 10.525 A 4.175 4.175 0 0 0 25.556 9.975 Q 25.005 9.806 24.336 9.732 A 10.155 10.155 0 0 0 23.225 9.675 Z M 20.375 7.825 L 23.125 7.825 A 7.785 7.785 0 0 0 24.38 7.73 Q 25.043 7.622 25.583 7.389 A 3.832 3.832 0 0 0 26.263 7.013 A 2.942 2.942 0 0 0 26.969 6.333 A 2.419 2.419 0 0 0 27.425 4.875 A 2.795 2.795 0 0 0 27.276 3.941 Q 27.012 3.189 26.275 2.725 A 4.193 4.193 0 0 0 25.159 2.244 Q 24.285 2 23.125 2 Q 22.3 2 21.625 2.038 Q 20.95 2.075 20.375 2.125 L 20.375 7.825 Z"
          fill="currentColor"
        /> */}
        <text
          space="preserve"
          textAnchor="start"
          fontFamily="sans-serif"
          fontSize="40"
          strokeWidth="3"
          id="svg_2"
          y="60"
          x="13"
          stroke="currentColor"
          fill="currentColor">
          VB
        </text>
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
